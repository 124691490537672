import { sendFacebookEvent } from "@/assets/js/utils/utils";
import { SaveAnalyticsData } from "../../api/analytics";
import { SaveAnalyticsParams } from "../../api/interfaces/analytics.interface";
import { useRequest } from "../../api/utils";
import { removeUndefinedValues } from "@/assets/js/utils/functions";

export enum PAGE_TYPE {
  STORE = "STORE",
  ITEM = "ITEM",
}

const useAnalytics = (pageType: PAGE_TYPE, ref?: string) => {
  const { makeRequest } = useRequest<SaveAnalyticsParams>(SaveAnalyticsData);

  const saveAnalytics = async (data: { store: string; item?: string; itemName?: string }) => {
    const { store, item, itemName } = data;
    const sessionData: { stores: string[]; items: string[] } = JSON.parse(
      window.sessionStorage.getItem("catlog-analytics")
    ) || { stores: [], items: [] };
    let hasBeenSaved: boolean;

    if (item) {
      hasBeenSaved = sessionData.items.includes(item);
    } else {
      hasBeenSaved = sessionData.stores.includes(store);
    }

    if (!hasBeenSaved) {
      sendFacebookEvent(
        "ViewContent",
        removeUndefinedValues({
          content_category: item ? "product" : "storefront",
          content_ids: item ? [item] : undefined,
          content_name: item ? itemName : undefined,
        })
      );
      const [res, err] = await makeRequest({ store, item, page_type: pageType, ref });

      if (err) {
        return;
      }

      if (item) {
        sessionData.items.push(item);
      } else {
        sessionData.stores.push(store);
      }

      sessionStorage.setItem("catlog-analytics", JSON.stringify(sessionData));
    }
  };

  return {
    saveAnalytics,
  };
};

export default useAnalytics;
