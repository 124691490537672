import React from "react";
import Dropdown, { DropdownItem as IDropdownItemNew } from "./dropdown";
import { CURRENCIES } from "../../assets/interfaces";
import classNames from "classnames";

interface Props {
  currencies: CURRENCIES[];
  setCurrency: (currency: CURRENCIES) => void;
  selected: CURRENCIES;
}

const CurrencySwitcher: React.FC<Props> = ({ currencies, setCurrency, selected }) => {
  const dropdownItems: IDropdownItemNew[] = currencies.map((curr) => ({
    text: `${curr} ${CURRENCY_FLAG_MAP[curr]}`,
    link: undefined,
    onClick: () => setCurrency(curr),
    icon: null,
  }));

  return (
    <Dropdown items={dropdownItems} vPosition="BOTTOM" tiny>
      {(isOpen) => (
        <button className="h-8 sm:h-9 flex items-center dropdown-toggle bg-grey-fields-100 rounded-30 border border-grey-outline no-outline px-2 border-opacity-50 relative">
          <span className="inline-block mr-0.5 text-1xs text-dark font-medium">
            {selected} {CURRENCY_FLAG_MAP[selected]}
          </span>
          {/* prettier-ignore */}
          <svg viewBox="0 0 20 20" fill="none" className={classNames(`transition-transform text-dark transform flex-shrink-0 w-4 sm:w-5`, { 'rotate-180': isOpen})}>
            <path d="M15 7.5L10 12.5L5 7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      )}
    </Dropdown>
  );
};

const CURRENCY_FLAG_MAP = {
  [CURRENCIES.EUR]: "🇪🇺",
  [CURRENCIES.GBP]: "🇬🇧",
  [CURRENCIES.NGN]: "🇳🇬",
  [CURRENCIES.GHC]: "🇬🇭",
  [CURRENCIES.USD]: "🇺🇸",
  [CURRENCIES.ZAR]: "🇿🇦",
};

export default CurrencySwitcher;
