import cx from "classnames";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import useClickOutside from "../hooks/useClickOutside";

type FunctionChild = (cond: boolean) => ReactNode;

interface Props {
  items: DropdownItem[];
  vPosition?: "TOP" | "BOTTOM" | "";
  gap?: boolean;
  className?: string;
  children: ReactNode | FunctionChild;
  tiny?: boolean;
}

const Dropdown: React.FC<Props> = (props) => {
  const { vPosition = "", className, gap = true, tiny = false } = props;
  const [opened, setOpened] = useState(false);
  const buttonClass =
    "flex items-center p-2 pl-2.5 text-black-secondary pr-2 hover:bg-gray-100 w-full hover:bg-opacity-50 duration-150";
  const toggleCaret = useRef<SVGElement>(null);
  var dropdown = useRef<HTMLDivElement>(null);

  useClickOutside(dropdown, () => {
    setOpened(false);
  });

  useEffect(() => {
    if (dropdown.current) {
      const toggleCaretEl = dropdown.current.querySelector(".dropdown-caret") as SVGElement;
      const toggleEl = dropdown.current.querySelector(".dropdown-toggle");

      if (toggleCaretEl) {
        toggleCaret.current = toggleCaretEl;
      }

      if (toggleEl) {
        const toggleFn = (e: Event) => {
          e.stopPropagation();
          // console.log("CLICKKKEEEEDD");
          setOpened(!opened);
        };
        toggleEl.addEventListener("click", toggleFn);

        return () => {
          toggleEl.removeEventListener("click", toggleFn);
        };
      }
    }
  }, [opened]);

  useEffect(() => {
    if (toggleCaret.current) {
      toggleCaret.current.classList.toggle("rotate-180");
    }
  }, [opened]);

  // const handleItemClick = (item: DropdownItem) => {
  //   if (item.onClick) item.onClick();
  // };

  return (
    <div ref={dropdown} className={cx("inline-block relative", className)} onClick={(e) => e.stopPropagation()}>
      {/* {props.children} */}

      {typeof props.children !== "function" ? props.children : (props.children as FunctionChild)(opened)}

      <div
        className={cx(
          `z-[1000] w-full rounded-10 bg-white shadow-md absolute transform transition ease-out duration-300 right-0 overflow-hidden`,
          { "opacity-1 translate-y-0": opened },
          { "pointer-events-none opacity-0 translate-y-4": !opened },
          { "top-[calc(100%+10px)]": vPosition === "BOTTOM" && gap },
          { "bottom-[calc(100%+10px)]": vPosition === "TOP" && gap },
          { "bottom-[calc(100%)]": vPosition === "TOP" && !gap },
          { "top-[calc(100%)]": vPosition === "BOTTOM" && !gap },
          { "min-w-[100px]": tiny },
          { "min-w-[190px]": !tiny }
        )}
      >
        <ul>
          {props.items
            .filter((i) => !i.skip)
            .map((item, index) => (
              <li
                key={index.toString()}
                className="border-b border-grey-border border-opacity-50"
                onClick={() => setOpened(false)}
              >
                {/* {opened && ( */}
                {item.onClick && (
                  <button
                    key={index.toString()}
                    onClick={item?.disabled ? null : item.onClick}
                    className={cx(buttonClass, item.className, {
                      "!text-placeholder !cursor-not-allowed": item?.disabled,
                    })}
                  >
                    {item.icon ? <div className="flex-shrink-0">{item.icon}</div> : null}
                    <span className="text-[13.5px] ml-2 whitespace-nowrap inline-block">{item.text}</span>
                  </button>
                )}

                {item.link && (
                  <a
                    {...{
                      target: item.link.includes("http") ? "_blank" : "",
                      relative: item.link.includes("http") ? "noreferrer" : "",
                      href: item?.disabled ? "" : item.link,
                    }}
                    className={cx(buttonClass, item.className, {
                      "!text-placeholder !cursor-not-allowed": item?.disabled,
                    })}
                  >
                    {item.icon ? <div className="flex-shrink-0">{item.icon}</div> : null}
                    <span className="text-[13.5px] ml-2 whitespace-nowrap inline-block">{item.text}</span>
                  </a>
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export type DropdownItem = {
  text: React.ReactElement | string;
  link?: string;
  onClick?: VoidFunction | ((e?: React.MouseEvent) => void);
  icon?: JSX.Element;
  className?: string;
  skip?: boolean;
  disabled?: boolean;
};

export default Dropdown;
