import { createBrowserHistory } from "history";
import React, { useEffect, useRef, useState } from "react";
import usePreventFirstRun from "../hooks/usePreventFirstRun";
import useSearchParams from "../hooks/useSearchParams";

interface Props {
  show: boolean;
  toggle: (show: boolean) => void;
  resetPage: () => void;
}

const StoreFrontSearch: React.FC<Props> = ({ show, toggle, resetPage }) => {
  const searchRef = useRef(null);
  const { search } = useSearchParams(["search"]);
  const [query, setQuery] = useState("");

  usePreventFirstRun(() => {
    setQuery(query);

    // if (!query) {
    //   console.log("TRIGGERED");
    //   resetPage();
    // }
  }, [search]);

  useEffect(() => {
    if (show) {
      searchRef?.current?.focus();
    }
  }, [show]);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();

    const history = createBrowserHistory();
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("search", query);

    history.push({
      pathname: location.pathname,
      search: `?${searchParams.toString()}`,
    });
    resetPage();

    toggle(false);
    setQuery("");
  };

  return (
    <div
      className={`bg-primary-900 bg-opacity-50 fixed top-0 left-0 bottom-0 right-0 z-9999 modal transition-opacity duration-300 flex flex-col items-center py-12.5 sm:py-30 z-[999] blur-5 cursor-pointer ${
        show ? "opacity-1" : "opacity-0 pointer-events-none"
      }`}
      onClick={() => toggle(false)}
    >
      <form
        className={`bg-white w-11/12 mx-auto max-w-[550px] rounded-pre-md transform duration-500 delay-200 origin-bottom transition-all h-[55px] sm:h-16 rounded-[12px] sm:rounded-15 cursor-auto flex items-center overflow-hidden safari-overflow-fix ${
          show ? "scale-100 opacity-100 translate-y-0" : "scale-90 opacity-0 translate-y-10"
        }`}
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSearch}
      >
        <input
          type="text"
          className="w-full h-full bg-white no-outline text-base sm:text-[17px] px-3.5 sm:px-5 placeholder:text-placeholder focus:placeholder-opacity-75 text-black-secondary ease-out duration-200 transition-all"
          placeholder="Search for items"
          onChange={(e) => setQuery(e.target.value)}
          value={query}
          ref={searchRef}
        />
        <button
          className={`items-center justify-center h-9 w-9 sm:h-10 sm:w-10 rounded-[8px] bg-white text-dark transition-all ease-in-out hover:bg-grey-fields-100 hover:text-primary-500  duration-100 flex itesms-center absolute right-2.5 sm:right-3.75`}
          onClick={() => {}}
        >
          {/* prettier-ignore */}
          <svg className="w-4 sm:w-5" viewBox="0 0 15 15" fill="none">
            <path d="M6.875 11.875C9.63642 11.875 11.875 9.63642 11.875 6.875C11.875 4.11358 9.63642 1.875 6.875 1.875C4.11358 1.875 1.875 4.11358 1.875 6.875C1.875 9.63642 4.11358 11.875 6.875 11.875Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.125 13.125L10.4062 10.4062" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </form>
    </div>
  );
};

export default StoreFrontSearch;
