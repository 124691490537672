import React, { useEffect, useState } from "react";

const ErrorLabel: React.FC<{
  error?: string;
  perm?: boolean;
  setError?: (error: string) => void;
  className?: string;
}> = ({ error, perm = false, setError, className }) => {
  const [display, setDisplay] = useState("");

  useEffect(() => {
    if (error) {
      setDisplay(error);

      if (!perm) {
        setTimeout(() => {
          if (setError) {
            setError("");
          } else {
            setDisplay("");
          }
        }, 5000);
      }
    } else {
      setDisplay("");
    }
  }, [error]);

  return (
    <>
      {display ? (
        <div
          className={`w-full bg-accent-red-500 bg-opacity-10 text-accent-red-500 text-1xs font-medium p-2.5 text-center mb-6 rounded-5 ${className}`}
        >
          <span className="inline-block">{display}</span>
        </div>
      ) : null}
    </>
  );
};

export default ErrorLabel;
