import { createBrowserHistory } from "history";

interface Props {
  search?: string;
  onClear?: VoidFunction;
}

const ClearSearch: React.FC<Props> = ({ search, onClear }) => {
  const clearSearch = () => {
    const history = createBrowserHistory();
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("search");

    history.push({
      pathname: location.pathname,
      search: Array.from(searchParams.keys()).length > 0 ? `?${searchParams.toString()}` : "",
    });

    if (onClear) {
      onClear();
    }
  };

  if (!search) {
    return null;
  }

  return (
    <div className="mb-4 px-3 sm:px-4 py-2 rounded-10 border border-grey-input flex items-center justify-between overflow-hidden">
      <span className="inline-block text-sm  text-dark pt-1 flex-1 overflow-hidden overflow-ellipsis whitespace-nowrap mr-5">
        Showing results for: <b className="font-bold">{search}</b>
      </span>
      <button className="no-outline inline-flex text-primary-500 text-sm items-center" onClick={clearSearch}>
        <span className="inline-block">Clear Search</span>
        {/* prettier-ignore */}
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" className="ml-0.5">
          <path d="M11.25 3.75L3.75 11.25" stroke="currentColor" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M3.75 3.75L11.25 11.25" stroke="currentColor" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
    </div>
  );
};

export default ClearSearch;
