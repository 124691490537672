import { paramsFromObject } from "../assets/js/utils/functions";
import { GetStoreItemsParams, VerifyCouponsParams } from "./interfaces/items.interface";
import { request } from "./utils";

export interface PaginateSearchParams {
  page?: number;
  per_page?: number;
}

const GetStoreItems = (data: GetStoreItemsParams) => {
  const params = paramsFromObject(data);
  return request(`items/public?${params}`, "get");
};

const GetCoupons = (data: PaginateSearchParams) => {
  const params = paramsFromObject(data);
  return request(`coupons?${params}`, "get");
};
const VerifyCoupon = (data: VerifyCouponsParams) => {
  return request(`coupons/verify`, "post", { data });
};

export { GetStoreItems, GetCoupons, VerifyCoupon };
